/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import { useNavigate } from "react-router-dom";

const CompleteProfilePage = () => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            
        </React.Fragment>
    );
}

export default CompleteProfilePage;