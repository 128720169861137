export const ActionType: any = {
  // User Actions

  CURRENT_USER:        'CURRENT_USER',
  CURRENT_USER_FAILED: 'CURRENT_USER_FAILED',
  USER_LOGIN_SUCCESS:  'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAIL:     'USER_LOGIN_FAIL',
  USER_PAYMENT_INFO:   'USER_PAYMENT_INFO',
  USER_TRANSACTIONS:   'USER_TRANSACTIONS',
  USER_CREATION_SUCCESS:  'USER_CREATION_SUCCESS',
  USER_CREATION_FAILED:   'USER_CREATION_FAILED',

  GET_SOUNDS_FAIL:     'GET_SOUNDS_FAIL',
  GET_SOUNDS:          'GET_SOUNDS',

  GET_SOUND_SAMPLES:     'GET_SOUND_SAMPLES',
  GET_SOUND_SAMPLES_FAIL:'GET_SOUND_SAMPLES_FAIL',
};

export default ActionType;
