/**
 * @file constants.ts
 * 
 * This file contains globally used constant values for the application.
 * These constants include configuration values, URLs, feature toggles,
 * error messages, and any other data that remains unchanged throughout
 * the application's lifecycle.
 *
 * Keeping constants in this file ensures easy maintenance and prevents
 * magic numbers or hardcoded values scattered throughout the codebase.
 */


export const DEF_PUBLISHING_OFFER = "15"; // Percent
export const DEF_MASTER_OFFER  = "1";  // Percent

export {};