/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */

import PlayerContainer from "components/player/player-container";

const MyLikesPage = () => {

  return(
    <PlayerContainer source="likes"/>
  );
};

export default MyLikesPage;